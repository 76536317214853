import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
// import Profile from '../routes/rank';

const App = () => (
	<div id="app">
		{/* <Header /> */}
			<Router>
				<Route path="/" component={Home} />
				{/* <Route path="/profile/" component={Profile} user="me" />
				<Route path="/profile/:user" component={Profile} /> */}
			</Router>
	</div>
);

export default App;
